import React from 'react';
//import clsx from 'clsx';
//import { makeStyles } from '@material-ui/core/styles';

/*const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    width:'100vw',
    height: '100vh',
    overflow: 'auto',
  }
}));*/

export default function Location() {
  //const classes = useStyles();
  return (
    <div>Location works!!</div>
  );
}

