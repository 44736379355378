import {createContext, useContext} from 'react';

export const AuthContext = createContext();

export function useAuth(){
    return useContext(AuthContext);
}
/*
import React from 'react'

const TokenContext = React.createContext()

export const TokenProvider = TokenContext.Provider
export const TokenConsumer = TokenContext.Consumer

export default TokenContext;*/