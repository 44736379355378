import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
        width: '80%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        }
    }
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();
  return (
    <div>
        <Backdrop className={classes.backdrop} open={props.show}>
                <CircularProgress color="secondary" size={60} />
        </Backdrop>
    </div>
  );
}