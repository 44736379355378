import React, {useState} from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Login from './login/login';
import Home from './Home/home';
import Dashboard from './Dashboard/dashboard';
import Header from '../Header/header';
import { HashRouter, Switch, Route} from 'react-router-dom';
import {AuthContext} from '../context/auth';
import PrivateRoute from './privateRoute';
import Logout from './Logout/logout';
import Report from './Report/report';
import Billing from './Billing/billing';
import Locations from './Location/locations';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Organization from './Organization/oraganization';

const drawerWidth = 240;

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00458e'
        },
        secondary: {
            main: '#1da838',
            light: '#1da838'
        }/*,
        status:{
            danger:"red"
        }*/
    }
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    width:'100vw',
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '45vh',
  },
}));


//const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

function App() {
    const existingTokens = JSON.parse(localStorage.getItem("strapin_user_info"));
    const [authTokens, setAuthTokens] = useState(existingTokens);
    //const [authTokens, setAuthTokens] = useState("");
    const setTokens = (data) => {
        
        localStorage.setItem("strapin_user_info", JSON.stringify(data));
        setAuthTokens(data);
    }
    return (
        <div className="App">
            <AuthContext.Provider  value={{ authTokens, setAuthTokens: setTokens }}>
                
            <MuiThemeProvider theme={theme}>
                <HashRouter>
                    <Switch>        
                        <Route exact path="/" component={Login}/>
                        <Route exact path="/login" component={Login}/>
                        <PrivateRoute component={DefaultContainer}/>
                    </Switch>
                </HashRouter>
            </MuiThemeProvider>
        </AuthContext.Provider>
        </div>
    );
}

export default App;
  
var DefaultContainer = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <Header/>
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
                <Route path="/home" component={Home} />
                <Route path="/dashboard" component={Dashboard} /> 
                <Route path="/logout" component={Logout} /> 
                <Route path="/locations" component={Locations} /> 
                <Route path="/report" component={Report} /> 
                <Route path="/billing" component={Billing} /> 
                <Route path="/organization" component={Organization} /> 
            </Container>
        </main>
        </div>
    )
}