import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps";
import Geocode from "react-geocode";

class Directions extends Component {
    constructor(props){
        super(props)
        this.state = {
            defaultZoom: 4,
            map: null,
            center: {
              lat: 39.8333333,
              lng: -98.585522
            },
            location: [],
            showInfoIndex:null,
            address:""
        };
        //console.log(this.props.data);
        this.pos = [];
    }

    componentDidMount(){
        //console.log(this.props);
        Geocode.setApiKey("AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM");
 
        const mapArray =  this.props.data;
        //console.log(this.props.data);
        /*const refs = {};
        const onMapMounted = ref => {
            refs.map = ref;
        }
        console.log(refs.map);*/
        //var reg = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}");


        
        var waypts = [];
        mapArray.map((val, index) => {
            //console.log(index);
            if(index === 0){
                this.pos.push({"latlng":new window.google.maps.LatLng(val.gpsLat,val.gpsLng), "time":val.systemTime});
            }else{
                if(GpsDistance(val.gpsLat, val.gpsLng, mapArray[index-1].gpsLat, mapArray[index-1].gpsLng, "K") > 0.100){
                    this.pos.push({"latlng":new window.google.maps.LatLng(val.gpsLat,val.gpsLng), "time":val.systemTime});
                }else{
                    this.pos[this.pos.length - 1].latlng = new window.google.maps.LatLng(val.gpsLat,val.gpsLng);
                    this.pos[this.pos.length - 1].time = val.systemTime;
                }
            }
        }); 
        
        var k = 0;
        var j = 0;
        if(this.pos.length > 8){
            k = Math.round(this.pos.length / 8)			
            j = k;
        }
        this.pos.map((val, index) => {
            if(k!==0)
            {
                if(index === k)
                {
                    waypts.push({location: val.latlng, stopover: true });
                    k +=  j;							
                }
            }else{
                waypts.push({location: val.latlng, stopover: true});    
            }

        });
        

        /*for(var i in pos)
        {	
            if(k != ""){		
                if(i == k){							
                    waypts.push({location: pos[i].latlng, stopover: true });
                    k +=  j;							
                }
            }else{
                waypts.push({location: pos[i].latlng, stopover: true});}
            
            if(i == pos.length -1) marker = new google.maps.Marker({position: pos[i].latlng, map: dataHist.map, icon: dataHist.SetmapIcon(dArray), optimized: false, zIndex: google.maps.Marker.MAX_ZINDEX + 5});
            else{ marker = new google.maps.Marker({position: pos[i].latlng, map: dataHist.map, icon:"https://maps.gstatic.com/mapfiles/markers2/measle.png", optimized: false, zIndex: parseInt(i)});
                marker.zoomoutIcon = "https://maps.gstatic.com/mapfiles/markers2/measle.png";
                markers.push(marker);
            }
            
            if(events != undefined)events.push({marker:marker, time:pos[i].time});
            dataHist.InfoWindowEvent(dataHist.map,infowindow, pos[i],marker);
            bounds.extend(pos[i].latlng);
        }*/
        const DirectionsService = new window.google.maps.DirectionsService();        
        
            DirectionsService.route({
                origin: this.pos[0].latlng,
                destination: this.pos[this.pos.length - 1].latlng,
                waypoints: waypts,
                optimizeWaypoints: true,
                travelMode: window.google.maps.TravelMode.DRIVING,                
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    //console.log(this.refs.map);
                    //this._map.setZoom(1);
                    
                    this.setState({
                        directions: result,
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
            //console.log(this.pos);
    }
    componentDidUpdate(){
        //console.log(this.refs.map);

        const bounds = new window.google.maps.LatLngBounds();
        this.pos.map((item, i) => {        
            bounds.extend(item.latlng); 
        });        

        this.refs.map.fitBounds(bounds);
        //this.refs.map.setCenter(this.pos[0].latlng);
    }
    showInfo = (a, lat, lng) =>{        
        Geocode.fromLatLng(lat, lng).then(
            response => {
              const address = response.results[0].formatted_address;
                this.setState({
                    showInfoIndex:a,
                    address:address
                });
            },
            error => {
                this.setState({
                    showInfoIndex:a,
                    address:error
                });
            }
        );
    }
    onToggleOpen =()=>{
        //console.log("");
    }
  
  render() {
      if(!this.state.directions) return <div style={{position:"absolute",top:80,left:30}}>Loading...</div>;
      else{
        return (
            <GoogleMap
                ref ="map"
                defaultZoom={5}
                mapTypeId={"hybrid"}                
                tilt={0}
                defaultCenter={{lat: 39.74583204354556, lng: -104.34943802499998}}
                >
                {this.pos.map((item, i) => {
                    var icon = "https://maps.gstatic.com/mapfiles/markers2/measle.png";
                    if(i === 0)icon = "https://one.levelcon.com/images/marker_green1.png";
                    return <Marker key={i} defaultPosition={item.latlng} icon={icon} onClick={()=>{ this.showInfo(i, item.latlng.lat(), item.latlng.lng())}} defaultZIndex={i+100}>
                        {(this.state.showInfoIndex === i ) && <InfoWindow  onCloseClick={this.onToggleOpen}><div><div><b>{this.state.address}</b></div><br></br><div>Reported At : {item.time}</div></div></InfoWindow>}
                        </Marker>

                })}
                {this.pos.length > 1 && this.state.directions && <DirectionsRenderer directions={this.state.directions} options={{suppressMarkers:true}}/>}
            </GoogleMap>
        );
      }
  }
}

export default compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?&key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(Directions);

function GpsDistance(lat1, lon1, lat2, lon2, unit) 
{
	var radlat1 = Math.PI * lat1/180
	var radlat2 = Math.PI * lat2/180
	var radlon1 = Math.PI * lon1/180
	var radlon2 = Math.PI * lon2/180
	var theta = lon1-lon2
	var radtheta = Math.PI * theta/180
	var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	dist = Math.acos(dist)
	dist = dist * 180/Math.PI
	dist = dist * 60 * 1.1515
	if (unit==="K") { dist = dist * 1.609344 }
	if (unit==="N") { dist = dist * 0.8684 }
	return dist
}