import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '../context/auth';

function PrivateRoute({component: Component, ...rest}){
    const { authTokens } = useAuth();
    let varToken = "";
    if(authTokens)varToken = authTokens[0].token;
    const [token, setToken] = React.useState(varToken);
  return (
    <Route
      {...rest}
        render={props =>
            token ? (
            <Component {...props} />
        ) : (
            <Redirect
                to={{ pathname: "/", state: { referer: props.location } }}
            />
        )
      }
    />
  );
}

export default PrivateRoute;