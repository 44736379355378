import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
const axios = require('axios');
//import {useAuth} from '../context/auth';


function ApiCall(props){
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {        
        if(props.request){
            setShow(true);
            doOP(props.request).then(resp => {
                setShow(false);
                if(resp.success)props.onResponse(resp);
                if(resp.message === "unAuthorised token access!")props.history.push("/logout");
            });
        }
    }, [props.request]);
    
    return(<div>
        <SimpleBackdrop show={show}/>
    </div>)
}

export function doOP(data) {
    const params = new URLSearchParams();
    
    Object.keys(data).map(key => (
        params.append(key,data[key])
    ))
    let existingTokens = "";
    if(JSON.parse(localStorage.getItem("strapin_user_info")))existingTokens = JSON.parse(localStorage.getItem("strapin_user_info"))[0].token;
    return axios({
        method: 'post',
        url: '/api/',       
        headers: { Authorization: "Bearer " + existingTokens},
        data: params
    })
    .then(function (response) {        
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
    
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
        width: '80%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        }
    }
}));

export function SimpleBackdrop(props) {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={props.show}>
                <CircularProgress color="secondary" size={60} />
            </Backdrop>
        </div>
    );
}

export default withRouter(ApiCall);

/*doOP=async function(data, token){
    const params = new URLSearchParams();
    
    Object.keys(data).map(key => (
        params.append(key,data[key])
    ))

    axios({
        method: 'post',
        url: '/api/',       
        headers: { Authorization: "Bearer " + token},
        data: params
    })
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}*/

/*const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };*/


//.then(response => response.json())
//.then(data => console.log(data));
//url: 'https://cors-anywhere.herokuapp.com/https://www.api.com',
