import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import Link from '@material-ui/core/Link';
import DashGrid from './dashGrid';
//import MapContainer from './maps';
import MyMapComponent from './reactMaps';
import ApiCall from '../../Utils/restAPI';
import AssetHistory from './history';

const drawerWidth = 240;


const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    width:'100vw',
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '45vh',
  },
});

class Dashboard extends React.Component{    
    //static contextType = AuthContext;
    
    constructor(props){
        super(props);
        this.state = {
            data:{},
            request:false,
            rowIndex:'',
            showPop:false,
            show:true
        };
        
    }
    req = {
        "function": "starPinF100"    
    }

    componentDidMount(){            
        
        this.setState({
            request:this.req         
        });
        /*doOP(this.req).then(resp => {
            if(resp.success)this.setState({ data: resp.data, show: false });
            else if(resp.message === "unAuthorised token access!")this.props.history.push("/logout");
            else this.setState({ data: [], show: false });
        });*/
    }
    handleResponse = (resp) =>{
        
        if(resp.success)this.setState({ data: resp.data, show: false });    
        else this.setState({ data: [], show: false });
    }
    handleRowClick = (index) => {      
        this.setState({
            rowIndex:index            
        });
        if(index)
        {
            this.setState({
                showPop:true            
            });
        }
    }

    handlePopClose=(status) =>{
        this.setState({showPop: false});
    }

    render(){        
        const { classes } = this.props;
        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

        return (
            <div>
                {this.state.request ? <ApiCall request={this.state.request} onResponse={this.handleResponse}/> : null}
                { (this.state.showPop) ? <AssetHistory show={true} onPopClose={this.handlePopClose} historyID={this.state.rowIndex} /> : null }
                <Grid container spacing={1}>            
                    <Grid item xs={12}>
                        <Paper className={fixedHeightPaper}>
                        {!this.state.show ? <DashGrid data={this.state.data} onRowClick={this.handleRowClick}/>:<div>Loading..</div>}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={fixedHeightPaper}>
                            
                       {!this.state.show ? <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?&key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&libraries=visualization&libraries=geometry"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            data={this.state.data}
                            
                        />:<div>Loading..</div>}
                        </Paper>
                    </Grid>
                </Grid>      
            </div>
        );
    }
}
export default withStyles(useStyles)(Dashboard)
