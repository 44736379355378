import React, {useState} from 'react';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinkUI from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box'
//import LockOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ApiCall from '../../Utils/restAPI';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar'; 
import momentZone from 'moment-timezone';
import {useAuth} from '../../context/auth';
import logo from '../../images/Starpin_logo.svg';
import GoogleLogin from "react-google-login";


//import dashboard from './dashboard'
//import apiCall from './Uti'//
/*function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }*/
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(1, 0, 1),
    },
    leftDiv: {        
        display:"inline-block",
        float:"left",
        width:"49%"
    },
    root: {
        maxWidth: 345,
    },
    media: {
        height: 100,
        width:300,
        marginBottom:20
    },
  }));

  export default function SignIn(props) {
    let referer = "/dashboard"; 
    if(props.location.state)referer = props.location.state.referer || '/';

    const classes = useStyles();
    const [request, setRequest] = useState(false);
    const email = useFormInput(localStorage.StarPin_Login);    
    const password = useFormInput(localStorage.StarPin_pass);
    const vertical = 'top';
    const horizontal =  'center';
    const { setAuthTokens } = useAuth();
    const [open, setOpen] = React.useState(false);
    let defCheck=false;
    if(localStorage.StarPin_remember === "true")defCheck=true;
    const [defaltCheck, setDefaultCheck] = React.useState(defCheck);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };
    const checkChange = event =>{
        if(event.target.checked)
        {
            console.log(password.value);
            localStorage.StarPin_Login = email.value;				
			localStorage.StarPin_pass = password.value;
			localStorage.StarPin_remember = true;
        }else{
            localStorage.StarPin_Login = "";				
			localStorage.StarPin_pass = "";
            localStorage.StarPin_remember = false;
        }
    }
    
    const handleLogin = event => {      
        event.preventDefault();
        //setShow(true);
        let req = {
            "loginName": email.value,
            "loginPassword": password.value,
            "loginTimeZone": momentZone.tz.guess(),
            "function": "detailUser"    
        }
        setRequest(req);
    }
    const handleResponse =(resp) =>{
        setRequest(false);
        if(resp.success){
            setAuthTokens(resp.data);
            props.history.push(referer);
        }
    }
    const responseGoogle = (response) => {
        const req = {
            "googleID":response["profileObj"]["googleId"],
            "firstName":response["profileObj"]["givenName"],
            "lastName":response["profileObj"]["familyName"],
            "email":response["profileObj"]["email"],
            "avatar":response["profileObj"]["imageUrl"],
            "loginTimeZone": momentZone.tz.guess(),
            "function":"checkUser"
        }
        setRequest(req);
    }
   
    //if (isLoggedIn) {
        //return <Redirect to="/dashboard" />;
    //}
    return (        
      <Container component="main" maxWidth="xs">
        {request ? <ApiCall request={request} onResponse={handleResponse}/> : ""}
        <CssBaseline />
        <div className={classes.paper}>
          {/*<Avatar src={logo} />*/}
          <img src={logo} className={classes.media} alt="StarPin Logo"></img>
          {/*<Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={logo}
          title="Contemplative Reptile"
        />
      </CardActionArea>
      
    </Card>*/}
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`} onClose={handleClose}><Alert onClose={handleClose} severity="error">Invalid Credentials, Try again!</Alert></Snackbar>
          <form className={classes.form} noValidate>          
          
            <TextField variant="outlined" margin="normal" required fullWidth  label="Email Address" {...email} autoComplete="email" autoFocus />
            <TextField variant="outlined" margin="normal" required fullWidth {...password} label="Password" type="password" autoComplete="current-password" />
            <Grid container alignItems="flex-start" direction="row">
            <FormControlLabel control={<Checkbox value="remember" defaultChecked={defaltCheck} color="primary" onChange={checkChange}/>} label="Remember me" />
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}  onClick={handleLogin} >Log In</Button>
            <Grid container alignItems="flex-start" direction="row">
              <LinkUI href="#" variant="body2">Forgot password?</LinkUI>
            </Grid>
          </form>
            <Grid container style={{marginTop:"10px"}} direction="row" justify="center" alignItems="center" item xs={12}>
                <Grid item xs={6}>
                    <GoogleLogin
                        clientId="895842328235-fds2714in2b19ad8eobfbedioete3i05.apps.googleusercontent.com"
                        buttonText="Sign in with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}                
                    />
                </Grid>
            </Grid>
        </div>
      </Container>
    );
  }

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}