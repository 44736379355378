import React, {useEffect} from 'react';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
//import BottomNavigation from '@material-ui/core/BottomNavigation';
//import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
//import Box from '@material-ui/core/Box';
//import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
//import Fab from '@material-ui/core/Fab';
import ApiCall from '../../Utils/restAPI';
import SimpleTable from '../SimpleTable/SimpleTable';
import SimpleBackdrop from '../../Utils/backdrop';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import Directions from './mapDirections';
//import MapWithADirectionsRenderer from './Direction';
function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;
  //console.log(props);
    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
          <div>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {children}
                </Paper>
            </Grid>
          </div>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 500,
        backgroundColor: theme.palette.background.paper,
    },
    modalMaxHeigh:{
        height:"100vh",
    },
    dialogPaper: {
        borderRadius: 15,
        minHeight: '95vh',
        maxHeight: '100vh',
        width: '100%',
        ['@media (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: '60%',
            maxHeight: '95vh',
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    tabPanel:{
        overflow:'auto'
    },
    paper:{
        marginTop:"10px",
        margin: "10,10,10,10",

        height:'calc(100vh - 150px)'
    },
    closeIcon:{
        position:"absolute",
        right:10,
        top:10,
        cursor:"pointer"
    }
}));
function AssetHistory(props) {
    
    const [open, setOpen] = React.useState(props.show);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [hData, sethData] = React.useState([]);
    const [request, setRequest] = React.useState(false);
    const [showMap, setshowMap] = React.useState(false);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {        
        setOpen(props.show);        
        if(props.show){
            //sethshowDB(true);
            const req = {
                "function": "starPinHistory",
                "id":props.historyID    
            }
            setRequest(req);
            /*doOP(req).then(resp => {
                sethshowDB(false);
                setshowMap(true);
                if(resp.success){                    
                    sethData(resp.data);
                }
                else if(resp.message === "unAuthorised token access!")props.history.push("/logout");
                else this.setState({ data: [], show: false });
            });*/
        }
    }, [props.show]);

    const onRequestSuccess = (resp) =>{
        setRequest(false);
        setshowMap(true);
        if(resp.success) sethData(resp.data);
        else this.setState({ data: [], show: false });
    }
    /*const handleClickOpen = () => {
        setOpen(true);
    };*/

    const handleClose = () => {
        setOpen(false);
        props.onPopClose(false);
    };

    return (
        <div>            
        <Dialog
            classes={{ paper: classes.dialogPaper }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}            
            fullScreen
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            
            <DialogContent>
            <Icon className={classes.closeIcon} onClick={handleClose}>close</Icon>
            {request ? <ApiCall request={request} onResponse={onRequestSuccess}/> : null}
            {/*<DialogContentText id="alert-dialog-slide-description">
                Let Google help apps determine location. This means sending anonymous location data to
                Google, even when no apps are running.
            </DialogContentText>*/}
                
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon tabs example"
                >
                    <Tab icon={<Icon>map</Icon>} aria-label="map" ></Tab>
                    <Tab icon={<Icon>view_list</Icon>} aria-label="grid" ></Tab>                
                    
                </Tabs>
                <TabPanel value={value} index={0}>
                {showMap ? <Directions data={hData} /> : <div></div>}
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabPanel}>
                    <SimpleTable data={hData} />
                </TabPanel>
            
            </DialogContent>
            {/*<DialogActions>
            <Button onClick={handleClose} color="primary">
            <Icon>close</Icon>
            </Button>
            
            
            </DialogActions>*/}
        </Dialog>5
        </div>
    );
}

export default withRouter(AssetHistory);

/*function Tab1()
{
    return <div>HI HOW ARE YOU?</div>;
}*/

/*
import React,{useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AssetHistory(props) {
    console.log(props);
    const [open, setOpen] = React.useState(props.show);
    //setOpen(props.show);
    //console.log(open);

    useEffect(() => {
        setOpen(props.show)
    }, [props.show]);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.onPopClose(false);
    };

    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Let Google help apps determine location. This means sending anonymous location data to
                Google, even when no apps are running.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Disagree
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
                Agree
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}*/