import React from 'react';
import PaypalButtons from '../paypal/paypal';
//import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon';




const useStyles = theme => ({
    root: {
      display: 'flex',
    },
    content: {
      //flexGrow: 1,
        width:'100vw',
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    },
    billingGrid: {
        height: "calc(100vh - 90px)",
        overflow:"auto"
    },
    billingHistory: {
        height: "45vh",
        overflow:"auto"
    },
    paymentHistory: {
        height: "45vh",
        overflow:"auto"
    },
    table: {
        minWidth: 700,
    },
    centerPaper: {
        padding: theme.spacing(3, 2),
        height: "45vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }
});

const newStyle = makeStyles({
    table: {
      minWidth: 700,
      minHeight: 450,
      overflow:"auto"
    },
    tablecell: {
        fontSize: 11,
    },
    tableHeder: {
        fontSize: 11,
        background:"#F3F3F3"
    },
    card_root: {
        minWidth: 275,
        height:"20vh"
      },
    card_bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    card_title: {
        fontSize: 14,
      },
    card_pos: {
        marginBottom: 12,
    },
    dialogPaper: {
        borderRadius: 15,
        minHeight: '95vh',
        maxHeight: '100vh',
        width: '100%',
        ['@media (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: '35%',
            maxHeight: '95vh',
        }
    },
});

let totalDue = 0;
  
class Billing extends React.Component {
    constructor(props){
        super(props)
        
    }
    state = {
        showPaypal: false
      };
    
      showPaypalButtons = () => {
        this.setState({ showPaypal: true });
      };
    
    render() {
        const { classes } = this.props;
        const { showPaypal } = this.state;
        if (showPaypal) {
          return <PaypalButtons />;
        } else {
          return (
            /*<div className="main">
              <h2> Pay Outstanding </h2>
              <img alt="StarPin" src='' />
              <h3>
                <b>$200</b>
              </h3>
              <button onClick={this.showPaypalButtons}> Pay </button>
            </div>*/
            <Grid container spacing={1}>            
                <Grid item xs={12} lg={12}>
                <Paper className={classes.billingHistory}>
                    <Container> 
                        <SpanningTable/>
                    </Container>
                </Paper>
                </Grid>
                <Grid container item xs={12} lg={12} spacing={1}>
                <Grid item xs={12} lg={4}><Paper className={classes.centerPaper}><PayBill/></Paper></Grid>
                <Grid item xs={12} lg={4}><Paper className={classes.billingHistory}>Payment History<br/><br/><h1>Payment history will appear here.</h1></Paper></Grid>
                <Grid item xs={12} lg={4}><Paper className={classes.billingHistory}>Billing History<br/><br/><h1>Billing history will appear here.</h1></Paper></Grid>
                </Grid>
            </Grid>
          );
        }
    }
}


function  PayBill(){
    const [showPaypal, setShowPaypal] =  React.useState(false);
    const showPaypalButtons = (event) => {
        setShowPaypal(true);
    };

    const handlePopClose =() =>{
        setShowPaypal(false);
    }
    const classes = newStyle();
    const bull = <span className={classes.bullet}>•</span>;
    return(
        <div>
            { (showPaypal) ? <Popup show={true} onPopClose={handlePopClose}/> : null }
            <Card className={classes.card_root}>
                <CardContent>
                    <Typography varient="h5" color="textSecondary" gutterBottom>
                    Current Outstanding
                    </Typography>                    
                    <Chip color="primary" label={totalDue}></Chip><br/><br/>
                    <Button variant="contained" color="secondary" onClick={showPaypalButtons}>Pay Bill</Button>
                </CardContent>                
            </Card>
            <Card style={{marginTop:20}} className={classes.card_root}>
                <CardContent>
                <Typography varient="h5" color="textSecondary" gutterBottom>
                    AutoPay
                    </Typography>
                    <FormControlLabel
                        control={
                        <Switch                            
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Enable"
                    />
                    <Typography variant="body2" component="p">
                    (Setup autoPay and be assure.
                    <br />
                    {'"uninterrupted service")'}
                    </Typography>
                </CardContent>                
            </Card>
            {/*<h1>Total Due</h1>
            <h1><Chip color="primary" label={totalDue}></Chip></h1>
            <Button variant="contained" color="secondary">
                Pay Bill
            </Button>*/}
        </div>   
        
    )
}
export default withStyles(useStyles)(Billing)

const TAX_RATE = 0.07;


function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}


const rows = [
    createRow('3C71BF290920', 1 ,0.1),
    createRow('9454933638B9', 1 ,0.1),
    createRow('9454933649D9', 1 ,0.1),
    createRow('94549337006F', 1 ,0.1),
    createRow('94549362C626', 1 ,0.1),
    createRow('990002187713704', 1 ,0.1),
    createRow('990002187715287', 1 ,0.1),
    createRow('990002187715402', 1 ,0.1),
    createRow('990002187749708', 1 ,0.1),
    createRow('990002187749906', 1 ,0.1),
    createRow('990002187752249', 1 ,0.1),
    createRow('990002187752637', 1 ,0.1),
    createRow('990002187752819', 1 ,0.1),
    createRow('990002187753528', 1 ,0.1),
    createRow('990002187754401', 1 ,0.1),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = Math.round((invoiceTaxes + invoiceSubtotal) * 100) / 100
//parseFloat(invoiceTaxes + invoiceSubtotal).toFixed(2);
totalDue = invoiceTotal;
function SpanningTable() {
  const classes = newStyle();

  return (
    <div>
        <h3>Billing Summary</h3>
        <TableContainer component={Paper}>
        <Table padding={"defult"} size="small" className={classes.table} aria-label="spanning table">
            <TableHead >
            <TableRow>
                <TableCell classes={{ root: classes.tablecell }} align="center" colSpan={3}>
                <b>Details</b>
                </TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center"><b>Price($)</b></TableCell>
            </TableRow>
            <TableRow>
                <TableCell classes={{ root: classes.tableHeder }}>Device ID</TableCell>
                <TableCell classes={{ root: classes.tableHeder }} align="center">Qty.</TableCell>
                <TableCell classes={{ root: classes.tableHeder }} align="center">Fees</TableCell>
                <TableCell classes={{ root: classes.tableHeder }} align="center">Sum</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow key={row.desc}>
                <TableCell classes={{ root: classes.tablecell }}>{row.desc}</TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center">{row.qty}</TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center">{row.unit}</TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center">{ccyFormat(row.price)}</TableCell>
                </TableRow>
            ))}

            <TableRow>
                <TableCell classes={{ root: classes.tablecell }} rowSpan={3} />
                <TableCell classes={{ root: classes.tablecell }} colSpan={2}>Subtotal</TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center">{ccyFormat(invoiceSubtotal)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell classes={{ root: classes.tablecell }}>Tax</TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center">{ccyFormat(invoiceTaxes)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell classes={{ root: classes.tablecell }} colSpan={2}><b>Total</b></TableCell>
                <TableCell classes={{ root: classes.tablecell }} align="center"><b>{ccyFormat(invoiceTotal)}</b></TableCell>
            </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        <br/>
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Popup(props) {
    
    const [open, setOpen] = React.useState(props.show);
    const classes = newStyle();
    const [value, setValue] = React.useState(0);
    //const [hData, sethData] = React.useState([]);
    //const [showDB, sethshowDB] = React.useState(false);
    //const [showMap, setshowMap] = React.useState(false);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    React.useEffect(() => {        
        //setOpen(props.show);        
        /*if(props.show){
            sethshowDB(true);
            const req = {
                "function": "starPinHistory",
                "id":props.historyID    
            }
            doOP(req).then(resp => {
                sethshowDB(false);
                setshowMap(true);
                if(resp.success){                    
                    sethData(resp.data);
                }
                else if(resp.message === "unAuthorised token access!")props.history.push("/logout");
                else this.setState({ data: [], show: false });
            });
        }*/
    }, [props.show]);

    /*const handleClickOpen = () => {
        setOpen(true);
    };*/

    const handleClose = () => {
        setOpen(false);
        props.onPopClose(false);
    };

    return (
        <div>            
        <Dialog
            classes={{ paper: classes.dialogPaper }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}            
            fullScreen
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            
            <DialogContent>
            <Icon className={classes.closeIcon} onClick={handleClose} >close</Icon>
            {/*<SimpleBackdrop show={showDB}/>*/}
            {/*<DialogContentText id="alert-dialog-slide-description">
                Let Google help apps determine location. This means sending anonymous location data to
                Google, even when no apps are running.
            </DialogContentText>*/}
                
                <PaypalButtons due={totalDue}/>
            
            </DialogContent>
            {/*<DialogActions>
            <Button onClick={handleClose} color="primary">
            <Icon>close</Icon>
            </Button>
            
            
            </DialogActions>*/}
        </Dialog>
        </div>
    );
}
