import React,{useState, useEffect} from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import Geocode from "react-geocode";

const MyMapComponent = withScriptjs(withGoogleMap((props) =>{
    //console.log(props.InfoIndex);
    const [showInfoIndex, setInfoIndex] = useState(props.InfoIndex);
    const [address, setAddress] = useState("");
    //console.log(showInfoIndex);
    useEffect(() => { setInfoIndex(props.InfoIndex) }, [props.InfoIndex]);
    Geocode.setApiKey("AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM");
    const bounds = new window.google.maps.LatLngBounds();
    props.data.map((item, i) => {
        const latLng = new window.google.maps.LatLng(item.gpsLat, item.gpsLng);
        bounds.extend(latLng); 
    });

    /*const coordinates = props.data.map(item => {
        //const { latitude, longitude } = model.particulars.location.point;
        const latLng = new window.google.maps.LatLng(item.gpsLat, item.gpsLng);
        bounds.extend(latLng);
        return latLng;
    });*/
      //console.log(displayMarkers);
    const showInfo = (a, lat, lng) =>{
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                setInfoIndex(a);
                setAddress(address);                    
            },
            error => {
                setInfoIndex(a);
                setAddress(error);
            }
        );
    }
    /*<GoogleMap ref={map => map && map.fitBounds(bounds)}*/
    return(
        
        <GoogleMap
        defaultZoom={4}
        defaultCenter={{ lat: 41.8507300, lng: -87.6512600 }}
         >
         {props.data.map((item, i) => {
            return <Marker key={item.id} position={{ lat: item.gpsLat, lng: item.gpsLng }} onClick={()=>{ showInfo(item.id, item.gpsLat, item.gpsLng)}}>
                {(showInfoIndex === item.id ) && <InfoWindow  onCloseClick={props.onToggleOpen}><div><div>DeviceID : {item.id}</div><div >Address : {address}</div></div></InfoWindow>}
                </Marker>

        })}
    </GoogleMap>
    )
}))


export default MyMapComponent;