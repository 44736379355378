import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import SimpleBackdrop from '../../Utils/backdrop';
import doOP from '../../Utils/restAPI';
import {AuthContext} from '../../context/auth';
//import Box from '@material-ui/core/Box'

//import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    width:'100vw',
    height: '100vh',
    overflow: 'auto',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

  
class Organization extends React.Component {
    static contextType = AuthContext;
    constructor(props){
        super(props)
        this.state = {
            id:"",
            name:"",
            address:"",
            phone:"",
            contact:"",
            showLoader:false
        }
    }
    
    
    componentDidMount(){
        console.log(this.context.authTokens);
        this.setState({id:  this.context.authTokens[0].companyID});
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    updateCompany = event => {
        let req = this.state;
        if(event.currentTarget.form.reportValidity()){
            if(this.state.id)req["function"] = "updateOrganization";
            else req["function"] = "addOrganization";
            console.log(req);
            /*this.setState({showLoader: true });
            doOP(req).then(resp => {
                this.setState({showLoader: false });
                if(resp.success){
                    //setLoggedIn(true);
                    
                    
                }
            });*/
        }
        //console.log(this.state);
    }
    render(){
        const { classes } = this.props;
        return (
      
            <Container component="main" maxWidth="xs">
                <SimpleBackdrop show={this.state.showLoader} />
                <form className={classes.form}>
                    <TextField fullWidth  value={this.state.id} name="id" style={{display:"none"}}/>
                    <TextField variant="outlined" margin="normal" onChange={this.handleChange} required fullWidth  value={this.state.name} name="name" label="Name" autoComplete="email" autoFocus />
                    <TextField variant="outlined" margin="normal" onChange={this.handleChange} fullWidth label="Address" value={this.state.address} name="address" autoComplete="address" multiline rows={4} />
                    <TextField variant="outlined" margin="normal" onChange={this.handleChange} fullWidth  label="Phone" value={this.state.phone} name="phone" autoComplete="phone" autoFocus />
                    <TextField variant="outlined" margin="normal" onChange={this.handleChange} fullWidth  label="Contact" name="contact" value={this.state.contact} autoComplete="contact" autoFocus />                
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}  onClick={this.updateCompany} >Save</Button>
                </form>
            </Container>
        );
    }
    
}
export default withStyles(useStyles)(Organization)
