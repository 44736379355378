import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
//import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
//import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';

/////////////
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import { deepOrange} from '@material-ui/core/colors';
//import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../images/Starpin_logo.svg';



//import Menu from './menu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    width:'100vw',
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '45vh',
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    marginLeft:10
  },
  listItemText:{
    fontSize:'0.7em',//Insert your required size
  },
  logo: {
    height: 50,
    width:150
},
}));

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);


export default function Header() {
//const { setAuthTokens } = useAuth();
//console.log()
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [header, setHeader] = React.useState("Dashboard");
  const [selected, setSelected] = React.useState("Home");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const updateSelected = (selectedIndex) => {
        setSelected(selectedIndex);
  };
  
  const handleChange = (name) =>
  {
    setHeader(name);
  }

  const mItems = [
    {id: 1, title: 'Home', icon:'home', nav:"/home"},
    {id: 2, title: 'Dashboard', icon:'dashboard', nav:"/dashboard"},
    {id: 3, title: 'Location', icon:'room', nav:"/locations"},
    {id: 4, title: 'Organization', icon:'people', nav:"/organization"},
    {id: 5, title: 'Reports', icon:'send', nav:"/report"},
    {id: 6, title: 'Billing', icon:'payment', nav:"/billing"},
    {id: 7, title: 'Logout', icon:'exit_to_app', nav:"/logout"}
  ]
  
  const subMItems = [
    {id: 8, title: 'Texas', icon:'gps_fixed', nav:"/dashboard"},
    {id: 9, title: 'California', icon:'gps_fixed', nav:"/dashboard"},
    {id: 10, title: 'Pune', icon:'gps_fixed', nav:"/dashboard"}
  ]
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {header}
          </Typography>
          <IconButton color="inherit"  onClick={handleClick}>
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>                    
                    <ListItemText classes={{primary:classes.listItemText}} primary="Payment Due" />
                </StyledMenuItem>
                <StyledMenuItem>                    
                    <ListItemText classes={{primary:classes.listItemText}} primary="Device Alert" />
                    </StyledMenuItem>
                <StyledMenuItem>                    
                    <ListItemText classes={{primary:classes.listItemText}} primary="Geofencing Alert" />
                </StyledMenuItem>
                <StyledMenuItem>                    
                    <ListItemText classes={{primary:classes.listItemText}} primary="Something.." />
                </StyledMenuItem>
            </StyledMenu>
          <Avatar className={classes.orange} onClick={handleClick}>CH</Avatar>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>                    
                    <ListItemText classes={{primary:classes.listItemText}} primary="Chaitanya Hajare" />
                </StyledMenuItem>
                <StyledMenuItem>                    
                    <ListItemText classes={{primary:classes.listItemText}} primary="Last Login" />
                    </StyledMenuItem>
                <StyledMenuItem>                    
                    <ListItemText classes={{primary:classes.listItemText}} primary="Logout" />
                </StyledMenuItem>
            </StyledMenu>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
        <img className={classes.logo} src={logo} alt="StarPin logo"></img>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {mItems.map(function(item){
            return(
            <ListItem button key={item.id} component={Link} to={item.nav} onClick={() => {handleChange(item.title); updateSelected(item.id)}} selected={selected === item.id}>  
              <ListItemIcon>
            <Icon>{item.icon}</Icon>

              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>)                
          })}    
          </List>
          <Divider />
          <List>
          <ListSubheader inset>Saved Locations</ListSubheader>
          {subMItems.map(function(item){
            return(
            <ListItem button key={item.id} component={Link} to={item.nav} onClick={() => {handleChange(item.title); updateSelected(item.id)}} selected={selected === item.id}>  
              <ListItemIcon>
            <Icon>{item.icon}</Icon>

              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>)                
          })}
          </List>
      </Drawer>
      
    </div>
  );
}

