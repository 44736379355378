import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        //minWidth: 650,
    },
    tablecell: {
        fontSize: 12,
        cursor:"pointer"
    }
});


export default function SimpleTable(props) {
    const rows = props.data;
    const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='small' aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tablecell} size="small">
            <TableCell classes={{ root: classes.tablecell }} size="small">SystemTime</TableCell>
            <TableCell classes={{ root: classes.tablecell }} size="small" align="right">Battery (mV)</TableCell>
            <TableCell classes={{ root: classes.tablecell }} size="small" align="right">CapLev (mV)</TableCell>
            <TableCell classes={{ root: classes.tablecell }} size="small" align="right">Sen 13V (mV)</TableCell>
            <TableCell classes={{ root: classes.tablecell }} size="small" align="right">Lat</TableCell>
            <TableCell classes={{ root: classes.tablecell }} size="small" align="right">Long</TableCell>
            <TableCell classes={{ root: classes.tablecell }} size="small" align="right">Source IP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.systemTime}>
              <TableCell component="th" scope="row" classes={{ root: classes.tablecell }}>
                {row.systemTime}
              </TableCell>
              <TableCell align="right" classes={{ root: classes.tablecell }}>{row.batLev}</TableCell>
              <TableCell align="right" classes={{ root: classes.tablecell }}>{row.capLev}</TableCell>
              <TableCell align="right" classes={{ root: classes.tablecell }}>{row.sen13V}</TableCell>
              <TableCell align="right" classes={{ root: classes.tablecell }}>{row.gpsLat}</TableCell>
              <TableCell align="right" classes={{ root: classes.tablecell }}>{row.gpsLng}</TableCell>
              <TableCell align="right" classes={{ root: classes.tablecell }}>{row.srcIP}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
