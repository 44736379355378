import React, {useEffect }  from 'react';
import {useAuth} from '../../context/auth';

export default function Logout(props) {
    const { setAuthTokens } = useAuth();
    useEffect(() => {
        setAuthTokens(null);
        props.history.push('/');
    });
    return (
    <div></div>
    );
}
  